import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';


export const Select = ({defaultValue , name ,  options , value = null  , className , onChange , keys = null })  => {
    
    /* Si las Keys no vienen definidas por el usuario las definiremos */
    if(!keys){
        keys = { 'id' : 'id' , nombre : 'nombre' }
    }
    
    return (
        
        <select defaultValue={value} onChange={onChange} className={className} name={name}>
            <option value={0}>Selecione un Valor de la Lista</option>
            {options.map((option , index) =>  
                <option  key={index} value={option[keys.id] }   >  {option[keys.nombre]}  </option>                       
            )}
        </select>
    );
}


export const Propoper = ( { texto_boton ,  children , keyChange , showPropover }) => {
    
    const [ estado , setEstado ] = useState(showPropover);


    useEffect( () => {      
        setEstado(false);
    }  , [keyChange])

    useEffect( () => {      
        setEstado(false);
    }  , [showPropover])
    
    return ( 
        <div className="popover__wrapper">
            <span onClick={() => setEstado(!estado)} className='link-danger link  link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' >
                {texto_boton}
            </span>
           
            { estado && 
                <div className="popover__content">
                    {children}             
                </div>
            }

           
        </div>
    )
}


