import { enviarPeticion } from "../../Core/ActionDatabase"
import { fetchEmpresa, fetchUSer } from "../../Core/fetchLocalStoreData"
import { __app } from "../../utils/myApp"

export const getLabelEstadoServidor = (estado) => {

    if (estado == 1){

        return (<span className="badge bg-success" >Sincronizado</span>)
    }
    return (<span className="badge bg-danger" >NoSincronizado</span>)
}


export const getLabelEstadoLocalFactura = (estado) => {

    if (estado == 1){

        return (<span className="badge bg-success" >Cerrada</span>)
    }
    return (<span className="badge bg-info" >Abierta</span>)
}


/***
 * Esta funcion recibe
 * const documentos = [
    { nombre: 'Venta', precio: 1000, tipo_documento_id: 1 },
    { nombre: 'Compra', precio: 300, tipo_documento_id: 2 },
    { nombre: 'Cotización', precio: 200, tipo_documento_id: 3 },
    { nombre: 'Venta', precio: 1000, tipo_documento_id: 1 }
];
 * y devuelve:
    
 Salida esperada:
// [
//   { nombre: 'Venta', tipo_documento_id: 1 },
//   { nombre: 'Compra', tipo_documento_id: 2 },
//   { nombre: 'Cotización', tipo_documento_id: 3 }
// ]

 */
export const filtrarTipoDocumento = (documentos) => {
    
    console.log(documentos);

    if(documentos == -1){
        return false;
    }
    // Usa un Map para asegurar que solo haya un documento por cada nombre único
    const documentosUnicos = Array.from(
    new Map(documentos.map(doc => [doc.CtTipoComprobanteFiscal_etiqueta_corta, { nombre: doc.CtTipoComprobanteFiscal_etiqueta_corta, CtTipoComprobanteFiscal_id: doc.CtTipoComprobanteFiscal_id }]))
    ).map(([, value]) => value);
    console.log(documentosUnicos)
    return documentosUnicos;
}



export const extraerInfoDocumento = async (id) => {
    let responseHttp = await enviarPeticion('documentos/view_documento' , id  );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}


export const paginacionDocumentos = async ({tipo,iniciar, limite}) => {
    
    let responseHttp = await enviarPeticion('documentos/lista_paginar_documentos' , tipo + "/"+  iniciar  + "/"  + limite  );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}


export const consultarDocumentos = async (formData) => {

    let copyformData = __app.clonarObjeto(formData)

    let empresa = fetchEmpresa();

    let tipo_documentos = empresa.datos_precarga.comprobantes.TipoComprobantes;
    const ids = copyformData.grupoDocumentos.map(item => parseInt(item.id));
    const resultados = tipo_documentos.filter(doc => ids.includes(parseInt(doc.ct_grupo_documento_id))).map(doc => doc.id )
   
    copyformData.grupoDocumentos = resultados;

    let responseHttp = await enviarPeticion('documentos/documentos_by_filter' , null , {
        body :copyformData
    } );

    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
    

   
}


export const reenviarDocuelectronico = async (id) => {

    let user = fetchUSer();
    let url_fe = null;

    if(user && user.ESTACION &&  user.ESTACION.servidor_facturaelectonica  ){
        url_fe =     user.ESTACION.servidor_facturaelectonica     
    }



    let responseHttp = await enviarPeticion('electronicos/re_enviar_factura_electronica' , id  ,  {
        body : url_fe
    } );

    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}