import {HelpHttp} from "./https";
import {fetchEmpresa  , fetchLocalconfig, getHostHttp} from "../Core/fetchLocalStoreData";





let ConfiServidor   =  null;

const configurarServer = () => {

   const ConfigEmpresa =  fetchEmpresa();
   const host          =  getHostHttp();

      ConfiServidor = {
      "URL" :  host  ,
      "nit" :  (ConfigEmpresa) ? ConfigEmpresa.nit : null ,
   }
}
configurarServer();




export const extraerItem =  () => {
   return     HelpHttp().post(ConfiServidor.URL + "clientes/listarProductos/p7_programacion_2"); 
}

export const changeServidor = (nit) => {
   ConfiServidor.nit = nit;
}

export const enviarPeticion =  ( url , parametros = '' , options = {} , ShowNit = true , abortController = null , configuraciones = null ) => {
   
   const host     =  getHostHttp(0);

  

   if(configuraciones && configuraciones.reladNit &&  configuraciones.reladNit === true ){
      configurarServer();
   }


   if(ShowNit){
      if(!ConfiServidor.nit){
         alert("no podemos enviar peticion sin nit.")
         return false;
      }
      url = host +  url  + "/" +  ConfiServidor.nit + "/" + parametros;
   }else{
      url = host +  url  +  "/" + parametros;
   }
   
   if( abortController == null ){

      abortController = 60000;
   }
   return     HelpHttp().post(url , options , abortController ); 
}

export const pingserver =  () => {
   let url = ConfiServidor.URL +   "ping_server.php";
   return     HelpHttp().post(url , {} , 3000 ); 
}



