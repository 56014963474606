
import ListaDocumentos from "./ListaDocumentos";



const  Index = ({header =true }) => {
    
    

    return (
        <div className='' >
         
           {
                header && <>
                    <h2 className='mt-2' >Lista de Documentos </h2>
                    <hr />
                </>
           }

            <div className="">
                <ul className="nav nav-underline  " align="center">
                    <li className="nav-item">
                        <a  className="click nav-link active" aria-current="page" >Todos</a>
                    </li>
                    {
                        /*
                        <li className="nav-item">
                        <a className="click nav-link" >Sincronizadas</a>
                    </li>
                    <li className="nav-item">
                        <a className="click nav-link" >Por Sincronizar</a>
                    </li>

                    <li className="nav-item">
                        <a className="click nav-link" >Guardados</a>
                    </li>
                        */
                    }
                
                </ul>

                <div className='mt-2 shadow-sm p-3 mb-5 bg-body rounded ' style={{"maxWidth" : "1200px"}} >
                    <ListaDocumentos  opciones={{}} funciones={ () => {}} />
                </div>
            </div>
        </div>
    );
}

export default Index;