import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCollased from './ButtonCollased';
import Notificaciones from './Notificaciones';
import Modulos from './Modulos';
import { useStateValue } from '../../../context/StateProvider';
import {actionType} from "../../../context/reducer"; 

const TopNav = () => {

    const [ {user} ,  dispatch   ] = useStateValue();

  

    const navigate = useNavigate();

    

    const handleClickCerrar = () => {     
        localStorage.removeItem('user');

        dispatch( {
            user : null,
            type : actionType.SET_USER 
        })
    
        navigate('/');
    }


    if(!user){
		return false;
	}

    return (
        <nav  className="navbar navbar-top navbar-slim fixed-top navbar-expand" id="topNavSlim" data-navbar-appearance="darker" style={{display: "block"}}>
            <div  className="collapse navbar-collapse justify-content-between">
                <div  className="navbar-logo">
                    <ButtonCollased />
                    <a  className="navbar-brand navbar-brand"> Pluma7 <span  className="text-body-highlight d-none d-sm-inline"> Punto de Venta</span></a>
                </div>







                <div className='collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center'>

                   
                    <Link to={'/crearDocumento'} className='btn btn-success'> FACTURAR </Link>
                   
                  
                </div>
                <ul className="navbar-nav navbar-nav-icons flex-row">
                    {
                        /*
                         <Link to={'/parqueadero'} className='ms-3 btn btn-warning'> PATIO </Link>
                         <li className="nav-item">
                        <div className="theme-control-toggle fa-icon-wait px-2">
                            <input className="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="phoenixTheme" value="dark" id="themeControlToggle" />
                            <label className="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span className="icon" data-feather="moon"></span></label>
                            <label className="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Switch theme"><span className="icon" data-feather="sun"></span></label>
                        </div>
                    </li>
                        */
                    }

                    <li className="nav-item ">
                        <span className='badge badge-phoenix badge-phoenix-warning'> Turno #45 </span>
                    </li>
                    {false && <Notificaciones />}

                    {false && <Modulos />} 
                    

                    <li className="nav-item dropdown">
                        <a className="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            <div className="avatar avatar-l ">
                                <img className="rounded-circle " src="../assets/img/team/40x40/57.webp" alt="" />
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border" aria-labelledby="navbarDropdownUser">
                            <div className="card position-relative border-0">
                                <div className="card-body p-0">
                                    <div className="text-center pt-4 pb-3">
                                        <div className="avatar avatar-xl ">
                                            <img className="rounded-circle " src="../assets/img/team/72x72/57.webp" alt="" />
                                        </div>
                                        <h6 className="mt-2 text-body-emphasis">  {user.USER.nombre}</h6>
                                    </div>
                                    <div className="mb-3 mx-3">
                                        {/**<input className="form-control form-control-sm" id="statusUpdateInput" type="text" placeholder="Update your status" /> */}
                                    </div>
                                </div>
                                {/*
                                <div className="overflow-auto scrollbar" style={{'height': '10rem'}}>
                                    <ul className="nav d-flex flex-column mb-2 pb-1">
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-body" data-feather="user"></span><span>Profile</span></a></li>
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"><span className="me-2 text-body" data-feather="pie-chart"></span>Dashboard</a></li>
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-body" data-feather="lock"></span>Posts &amp; Activity</a></li>
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-body" data-feather="settings"></span>Settings &amp; Privacy </a></li>
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-body" data-feather="help-circle"></span>Help Center</a></li>
                                        <li className="nav-item"><a className="nav-link px-3" href="#!"> <span className="me-2 text-body" data-feather="globe"></span>Language</a></li>
                                    </ul>
                                </div>
                                */}
                                <div className="card-footer p-0 border-top border-translucent">
                                    {
                                        /*

                                        */
                                    }
                                    <hr />
                                    <div className="px-3"> <a className="btn btn-phoenix-secondary d-flex flex-center w-100" onClick={handleClickCerrar}> <span className="me-2" data-feather="log-out"> </span>Cerrar Sesión</a></div>
                                    <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default TopNav;