import React , {useState} from 'react';
import { __app } from '../../../../utils/myApp';



const FormaPagoPaso3 = ({documento_actual , estadoServer  , totalpagado , user  }) =>  {


    /*** total pagado incluyendo saldo a FAVOR  */
    console.log(totalpagado , documento_actual.MATEMATICAS.TOTAL_SALDO_FAVOR  ) 
    const [TOTAL_PAGADO , SETTOTAL_PAGADO] =   useState(parseFloat(totalpagado) +  parseFloat(documento_actual.MATEMATICAS.TOTAL_SALDO_FAVOR)) ;

    return (
        <div>

            { (documento_actual.FORMAS_DE_PAGO  && documento_actual.FORMAS_DE_PAGO.length > 0)  && 
             <>
             
            <h1> FORMA DE PAGO </h1>

            {user.CONFIGURACIONES_TABLE.estado_aplicacion == 2 && <button onClick={ () => console.log(documento_actual, totalpagado) } > Log venta </button>}
            <table className='table table-responsive'>
                <thead>
                    <tr >
                        <td className=''></td>
                        <td className=''></td>
                    </tr>  
                </thead>
                
                <tbody>
                    {documento_actual.FORMAS_DE_PAGO.map( (formp , index) =>          
                       <>
                        
                        {
                            formp.total > 0 && 

                            <tr key={index} >
                                <td className=''>{formp.nombre}</td>
                                <td className=''>{formp.total}</td>
                        
                            </tr>  

                        }

                       </>             
                    )}
                    

                    
                    { (documento_actual.MATEMATICAS.TOTAL_SALDO_FAVOR && documento_actual.MATEMATICAS.TOTAL_SALDO_FAVOR  > 0 ) &&  
                    <tr>
                            <td className=''> SALDO A FAVOR </td>
                            <td className=''>{ documento_actual.MATEMATICAS.TOTAL_SALDO_FAVOR}</td>
                    </tr>  || <tr> <td></td> </tr> }

                </tbody>

                <tfoot>
                    <tr>
                        <th>TOTAL PAGADO</th>
                        <th> <h2>$ {  __app.formato_decimal({total: TOTAL_PAGADO })  }</h2></th>
                    </tr>  
                </tfoot>
            </table>
            

            { !estadoServer && <p><span className=" text-primary mb-3"> Trabajando sin Red </span></p> || <span className="text-principal">  </span>  }


            { (documento_actual.MATEMATICAS.SUBTOTAL_3 + documento_actual.MATEMATICAS.PROPINA) - TOTAL_PAGADO  > 0 && 
            
                <p className=' badge badge-phoenix   badge-phoenix-danger  fs-5'>Credito :     { __app.formato_decimal( {total :  (documento_actual.MATEMATICAS.SUBTOTAL_3 + documento_actual.MATEMATICAS.PROPINA) - TOTAL_PAGADO} ) }    </p>} 


            { TOTAL_PAGADO  - (documento_actual.MATEMATICAS.SUBTOTAL_3 + documento_actual.MATEMATICAS.PROPINA)  > 0 && <p  className='text-success' >Saldo a Favor:  { TOTAL_PAGADO - (documento_actual.MATEMATICAS.SUBTOTAL_3 + documento_actual.MATEMATICAS.PROPINA) }   </p> } 
            </>
            }

        </div>
    );
}



export default FormaPagoPaso3;
