import React from 'react';
import PedidoIndividual from './PedidoIndividual';



const Pedidos = ({pedidos , cargarDocumento , eliminarCuentaAbierta , user , imprimirPreFactura  }) => {


    if(!pedidos) {
        return (<p>No hay Pedidos para mostrar</p>);
    }


    return (
        <>
           
            { pedidos.sort((a, b) => a.FORM.mesa_id - b.FORM.mesa_id).map( (duc,keys) =>     
                <PedidoIndividual imprimirPreFactura={imprimirPreFactura} user={user} key={keys} duc={duc}  cargarDocumento={cargarDocumento}  eliminarCuentaAbierta={eliminarCuentaAbierta} />
            )}
   
        </>
    );
};

export default Pedidos;