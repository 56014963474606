import { useEffect, useState } from "react";

import { pingserver } from "../../Core/ActionDatabase";
import { getLocalstore, setLocalStore } from "../../Core/fetchLocalStoreData";

import StandarModal from './PartesDocument/StandarModal';
import Index from "../Documentos/LocalDocumentos/Index";
import { NotificationManager } from "react-notifications";
import TurnoCerrar from "../Turnos/TurnoCerrar";
import { Link } from "react-router-dom";

const BarraEstado = ({user, info}) => {

    const [ estadoRed , setEstadoRed] = useState("...");



    const [lastIdactual , SetlastIdactual  ] = useState(getLocalstore("firebaseLastKeyProducto") )

    const [showModalDocumento , SetshowModalDocumento ] = useState(  );


    const [showModalTurno , SetshowModalTurno ] = useState();

    /*** Con esta variable el usuario podra controlar el estado de la aplicacion. 
     * 
     *  Si esta variable esta Activa.
     *  Esto sigfica que: no hay internet
     */
    let estado_server_manual  = getLocalstore("estado_server_manual");

    if ( estado_server_manual === undefined || estado_server_manual === null || estado_server_manual === "" ){
        setLocalStore("estado_server_manual" , true);
        estado_server_manual = true;
    }



    const [ estadoServer , SetEstadoServer] = useState(estado_server_manual);


    const setBusquedaMan  = () => {

        SetEstadoServer( !estadoServer )
        localStorage.setItem('estado_server_manual' ,  !estadoServer);
    }


    const getLocalStoreSitch = () => {
        
        return estadoServer
    }



    useEffect( () => {

        let Intervalo = 1000;
        const obtenerPing = async () => {
            setEstadoRed("...")
            let data = await pingserver();
            if(data.server_error){
               
                setEstadoRed("desconectado");
                localStorage.setItem('statusServer' , 0);
                if  ( Intervalo < 25000){
                    Intervalo = Intervalo + 3000;
                }
                console.log(Intervalo);
                setTimeout(() => {   
                    obtenerPing();
                } , Intervalo )
            }else{
                if  (estadoRed == "desconectado"){
                    Intervalo = 30000;
                }
                setEstadoRed("conectado");
                localStorage.setItem('statusServer' , 1);

                if  ( Intervalo < 220000){
                    Intervalo = Intervalo + 60000;
                }
                console.log(Intervalo);
                setTimeout(() => {   
                    obtenerPing();
                } , Intervalo )
            }
        }


        obtenerPing();

        
        esperarKey();
        

    } , [])


    const esperarKey = async () => {

        while ( Number(lastIdactual) <=  0 ) {
           

            let aux = getLocalstore("firebaseLastKeyProducto");
            SetlastIdactual(aux);

            // Tu lógica dentro del bucle, si es necesario
            NotificationManager.warning("No tenemos Versión para  la  sincronización" , "Versión : " + lastIdactual )
            // Esperar 3000 milisegundos (3 segundos) antes de la siguiente iteración
            await new Promise(resolve => setTimeout(resolve, 9000));
        }
    }
    return (
        <div className="d-none d-sm-block">

       
        <footer id="react-pen-footer" className=" site-footer editor-footer">
            <div className="footer-left">
                <div className="footer-actions-menu">
                    <div className="footer-actions" data-expanded="false">
                        
                        <Link to={'/'} className="btn btn-subtle-secondary "  >
                           Menu
                        </Link>


                        <a  onClick={ () => SetshowModalDocumento(true)} className="ms-2 me-3 btn  btn-subtle-secondary  rounded border-0 "  >
                                Comprobantes
                        </a>
                        

                        <a  onClick={ () => SetshowModalTurno(true)} className="ms-1 me-3 btn  btn-subtle-secondary  rounded border-0 "  >
                                Cerrar Turno
                        </a>

                        <span> Usuario : {user.USER.nombre} </span>
                        <span> Punto de venta : {user.ESTACION.nombre} </span>
                        <span> | Turno #{user.TURNO.id}</span>
                        <span> | Caja #{user.TURNO.caja_id}</span>
                        <span> | Estado de Red : {estadoRed} </span>

                       
                       
                        <span> | Versión de  sincronización  ({ lastIdactual })
                            { Number(lastIdactual) > 0 &&  <span className="badge bg-success">
                                 
                            </span>
                            
                            ||  <span className="badge bg-warning">
                                 sin versión
                            </span>

                            }
                        </span>
                        
                        { /*
                            <button className="Button-module_root-xw+9D console-toggle-button" data-size="mini">Console</button><button data-appcues="footer-assets-button" className="Button-module_root-xw+9D" data-size="mini">Assets</button>
                            <button data-id="editor-comments-button" className="Button-module_root-xw+9D" data-size="mini">Comments</button>
                            <button title="Keyboard Shortcuts" className="Button-module_root-xw+9D keyboard-commands-button" data-size="mini"><span>Ctrl</span></button>
                        */}
                        <span className="" style={{ display : "inline-block" , marginLeft : "10px" }}>

                            { !estadoServer && <span className="text-warning"> Trabajando sin Red </span> || <span className="text-principal"> Trabajando con Red </span>  }

                            <div style={{ display : "inline-block" , position: "absolute"}} className="form-check form-switch  ">  
                                <input  style={{ marginTop : "5px" }}  onClick={  setBusquedaMan } defaultChecked={ getLocalStoreSitch()} value={estadoServer} className="form-check-input  " type="checkbox" role="switch" id="flexSwitchCheckDefault2" />
                            </div>
                        </span>
                        

                    </div>     
                </div>
            </div>
        </footer>


        {/***
        * Formulario Lista Compuesto, solo para restaurante.
        */}
        <StandarModal titulo="Comprobantes" headerShow={true} size="xl"   title="Productos Compuestos"  desactivar={() => SetshowModalDocumento(!showModalDocumento)}  mostrar={showModalDocumento}  > 
            <Index usuario={user} header={false}   /> 
        </StandarModal>


        {/***
        * Formulario Lista Compuesto, solo para restaurante.
        */}
        <StandarModal titulo="Cerrar Turno" headerShow={true} size="md"     desactivar={() => SetshowModalTurno(false)}  mostrar={showModalTurno}   > 
            <TurnoCerrar titulo={false} />
        </StandarModal>




        </div>
    );
}

export default BarraEstado;