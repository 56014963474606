import React, { useState } from 'react';
import { Parqueadero } from './Core/Parqueadero';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import ListaCategorias from '../Categorias/ListaCategorias';


const Pages = () => {

    const ClassParqueadero = new Parqueadero;
    

    const vehiculoInicial = {

        identificacion : '',
        nombre : '',
        objetos_categoria_id  : '',
    }


    const [vehiculo , setVehiculo ] = useState(vehiculoInicial)


    const [placa, setPlaca] = useState('');


    const [pasoActual , setPasoActual] = useState(-1);


    const handleKeyPress  = async (event) => {
        if (event.key === 'Enter') {
    
            let respuesta  = await ClassParqueadero.renderizarPlaca(placa);
            setPasoActual(respuesta)
        }
    };


    const extraerCategoria  = (categoria_id, descripcion) => {

        setVehiculo({
            identificacion : placa,
            nombre : descripcion,
            objetos_categoria_id  : categoria_id,
        })


        ClassParqueadero.crearParqueaderoDirecto();

    }

    return (
        <div>
            <h1>Modulo de parqueadero {pasoActual}</h1>
            
            {pasoActual == -1 && <input 
                placeholder='Placa' 
                value={placa} 
                onChange={(e) => setPlaca(e.target.value)}
                onKeyPress={handleKeyPress} 
            />}



            {pasoActual == 0 && <ListaCategorias  extraerCategoria={extraerCategoria}/>}

        </div>
    );
};

export default Pages;