import React from 'react';

const MaximosMinimos = ({alertaMaximoMinimos}) => {
    return (
        <div>
           <div className="card-body pt-2">
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap table-hover mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <h5 className="font-15 my-1">Nombre del producto Agoaddo</h5>
                                    <span className="text-muted font-13">Original : 8 </span> <br/> 
                                    <span className="text-muted font-13">Genericos : 5 </span>
                                </td>
                                <td>
                                    <span className="text-muted font-13">Maximo</span> <br/>
                                    <span className="">8</span>
                                </td>
                                <td>
                                    <span className="text-muted font-13">Minimos</span> <br/>
                                    <span className="font-14 mt-1 fw-normal">5</span>
                                </td>
                                <td>
                                    <span className="text-muted font-13">Faltantes</span>
                                    <h5 className="font-14 mt-1 fw-normal text-danger text-bold ">5</h5>
                                </td>
                                
                            </tr>
                                         
                        </tbody>
                    </table>
                </div> 

                </div>
        </div>
    );
};

export default MaximosMinimos;