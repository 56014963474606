import React from 'react';
import { useEffect } from 'react';
import {  onValue  } from "firebase/database";
import { useState } from 'react';
import Pedidos from './Pedidos';
import TodasLasMesas from './TodasLasMesas';
import { recentOpenFacRef } from '../../Core/FireBase';


const Mesas = ({user , cargarDocumento , eliminarCuentaAbierta , seleccionarMesa , imprimirPreFactura, funshowmesa , clasesEstilos}) => {

    



    
    let menuInicial = user.USER.vendedor   == 1 ? 1 : 2 ;

    const [ showtipoMesa , setShowtipoMesa ] = useState(menuInicial);


    const [ cuentasAbiertas , setCuentasAbiertas ] = useState([]);
    useEffect( () => {
        /*** 
         * Este Opservaor Esta pendiente si el utlimo ID de nuestra tabla a cambiado para llamarlo.
        */
        onValue(recentOpenFacRef(), (snapshot) => {
        
            const obj = snapshot.val();

            if(obj){
                var result = Object.keys(obj).map( (key2) => obj[key2]);
                setCuentasAbiertas(result)
            }else{
                setCuentasAbiertas([])
            }
            
            
        })
    }, [])






    return (
        <div className={clasesEstilos} >
            
            
          

            
            { (true) && 
                <div className=''>
                    <span className='mt me-3 btn btn-link' onClick={()=> setShowtipoMesa(1)}  > <i class="me-2 fa-solid fa-receipt"></i> Por Pedidos </span>

                    <span className='mt me-3 btn btn-link' onClick={()=> setShowtipoMesa(2)}  > <i class="me-2 fa-solid fa-table-cells"></i>  Por Mesas </span>

                    <span className='mt me-3 btn btn-link' onClick={()=> setShowtipoMesa(3)}  >Ver Mesas Libres </span>

                    <span className='mt me-3 btn btn-link' onClick={()=> setShowtipoMesa(4)}  >Ver Mesas Ocupadas </span>

                    <span className='mt me-3 btn text-danger  btn-link' onClick={()=> funshowmesa(false)}  > CERRAR </span>
                </div>
            }
            

            {/*mt-1 row row-cols-1 row-cols-md-2 row-cols-xl-4  g-4*/}
            <div className="row mt-1 row row-cols-1 row-cols-md-4 row-cols-xl-6  g-1">


                {showtipoMesa == 1 && <Pedidos imprimirPreFactura={imprimirPreFactura} user={user} pedidos={cuentasAbiertas} cargarDocumento={cargarDocumento} eliminarCuentaAbierta={eliminarCuentaAbierta} /> }

                {showtipoMesa == 2 && <TodasLasMesas imprimirPreFactura={imprimirPreFactura} seleccionarMesa={seleccionarMesa} pedidos={cuentasAbiertas} user={user} cargarDocumento={cargarDocumento}  eliminarCuentaAbierta={eliminarCuentaAbierta}    /> }

    

            </div>
        </div>
    );
};

export default Mesas;