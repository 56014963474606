import React from 'react';
import ListaDocumentos from '../../Documentos/LocalDocumentos/ListaDocumentos';

function ListarDocumentos({cargarDocumento }) { 


    if  (!cargarDocumento){
        cargarDocumento = () => {};
    }
    const opciones = {

        filtro : [
            {condicion : 'EstadoServidor'  , valor :  0 },
            {condicion : 'EstadoLocalFactura'  , valor :  0}
        ],

        botones : [
            'cargar',
        ]
    }

    const funciones =  {

        cargar : cargarDocumento
    }


    return (
        <div>
            <ListaDocumentos opciones={opciones} funciones={funciones} />
        </div>
    );
}

export default ListarDocumentos;