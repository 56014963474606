import React from 'react';
import { __app } from '../../../../../utils/myApp';
const InfoTerceroDocumentos = ({TotalFavor , info_sado , respuestaSi , cerrarModal }) => {

    const handleSi = () => {
        cerrarModal(false);
        respuestaSi(info_sado);
        
    } 

    return (
        <div>
            
           

            <div className='alert alert-success'>
                <h4 >Este Cliente tiene un saldo a favor </h4>
                <h1 className='mt-5'> Total a favor $ { __app.formato_decimal({ total : TotalFavor * -1}) } </h1>
                <p>Puedes usar este saldo a favor como forma de pago.</p>
            </div>

            <button className='btn btn-lg btn-principal' onClick={handleSi} >SI!!  usar este saldo</button>

            <button className='btn btn-' onClick={() => cerrarModal(false)} > Ignorar Saldo </button>  
        </div>
    );
};

export default InfoTerceroDocumentos;