import React, { useEffect, useState } from 'react';

import { NotificationManager } from 'react-notifications';
import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import Variantes from './Variantes';

const Pages = ({setFormData , formData}) => {


    const [stock , setStock] = useState(null);

    const [cantidad , setCantidad] = useState(0);

    const [observacion , setobservacion] = useState("Inventario Inicial");


    
    const user  =  fetchUSer();


    const realizarCambios = () => {

    
        // Si estás verificando si la longitud de la cantidad es menor que 0, 
        // eso probablemente no sea lo que deseas. 
        // También, verifica si cantidad es null o undefined primero.
        if (cantidad === null || cantidad === undefined || cantidad.length < 1) {
            NotificationManager.error("Escriba una cantidad Válida");
            return false;
        }
        
       
    }

    const setVaribleForm = (variantes) => {
        console.log(variantes);
        setData({variantes});
    }

    const guardarInventario = (e) => {

        e.preventDefault();
        setData();
        return false;
    };


    const setData = ({variantes}) => {
        console.log(variantes)
        setFormData({
            ...formData,
            INVENTARIO : {
                producto_id       :    null,
                estacione_id      :   user.ESTACION.id,
                cantidad_actual   :    0, 
                user_id           :    user.USER.id,
                observacion       :    observacion,
                cantidad          :    cantidad,
                VARIANTES         :    variantes
            }
        });
    }

    return (<Variantes setVaribleForm={setVaribleForm} variantesAux={formData.INVENTARIO.VARIANTES} />);
    return (
        <div className='col-md-6  col-12 '>
            
            

        
            {!formData.INVENTARIO &&  
                <>
                    <div className='mt-2'>
                        <label htmlFor="">Stock Inicial</label>
                        <input value={cantidad} onChange={ (e) =>  setCantidad(e.target.value) } type='number' className='form-control'/>
                    </div>

                    <div className='mt-4'>
                        <label htmlFor="">Escribe una Observación  </label>
                        <input value={observacion} onChange={ (e) =>  setobservacion(e.target.value) } type='text' className='form-control'/>
                    </div>


                    <button onClick={(e) => guardarInventario(e)} className='btn mt-2 btn-primary '>Guardar Inventario</button>

                </>
            }

            
            
            {formData.INVENTARIO &&  <p> Stock inicial : <b>{formData.INVENTARIO.cantidad}</b>  <a onClick={() => setFormData({
            ...formData,
            INVENTARIO : null
        }) } className='btn btn-sm btn-warning'> Editar  </a> </p>   }
        </div>
    );
};

export default Pages;
