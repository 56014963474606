import React, { useEffect, useRef, useState } from 'react';
import Select from './Filtros/Select';
import EstadoPagoDocumento from './Filtros/EstadoPagoDocumento';
import { extraerVendedores, listarCientesv2 } from '../../../../Terceros/Core';
import { fetchEmpresa, fetchUSer, getLocalstore } from '../../../../../Core/fetchLocalStoreData';
import { extraerUsuarios } from '../../../../user/Core/Core';
import { __app, __fecha } from '../../../../../utils/myApp';
import TipoDocumento from './Filtros/TipoDocumento';
import { consultarDocumentos } from '../../../controlador';
import SwitchProductos from './Filtros/SwitchProductos';
import { NotificationManager } from 'react-notifications';

const ListarTipos = ({ empresa, modulo , datosfiltro , ResultadoDatosfiltro , ResultadoDatosfiltroItems }) => {
  
    const {  grupoDocumentos } = empresa.datos_precarga.comprobantes;
    

    /** almacena todos los comprobantes del modulo */
    const tipoComprobantesbyModulo = grupoDocumentos.filter(( grupo ) =>   grupo.ct_operacione_id === modulo.id)

  
    const fe_municipios = empresa.datos_precarga.fe_municipios;

    const ListaMarcas =  getLocalstore("ListaMarcas") ;
    
    const [cargando , setCargando] = useState(null);

    const $btnFiltrar = useRef();

    const filtros = [

        {
            label: "Punto de Venta",
            name : "punto_venta",
            placeholder : "Seleccione una Sede ",
            options : empresa.estaciones
        },


        {
            label: "Cliente o Proveedor",
            name : "tercero",
            placeholder : "Seleccione una tercero ",
            options : datosfiltro.terceros,
            ShowOptions : {
                nombre : 'razonSocial',
            }
        },


        {
            label: "Vendedor",
            name : "vendedor",
            placeholder : "Seleccione un vendedor ",
            options : datosfiltro.vendedores
        },


        {
            label: "Usuario",
            name : "usuario",
            placeholder : "Seleccione un Usuario ",
            options : datosfiltro.usuarios
        },

        {
            label: "Ciudad",
            name: "ciudad",
            placeholder : "Seleccione una Ciudad ",
            options : fe_municipios
        },

    ]

    

    const FiltrosProductos = [
        
        {
            label: "Categorias",
            name : "producto_marca",
            placeholder : "Seleccione una Categoría ",
            options : ListaMarcas
        },

    ]



    // State hooks for form data
    const [formData, setFormData] = useState({
        desde: __app.getFecha('dia'),
        hasta:  __app.getFecha('dia'),
        hora1: "00:00",
        hora2: "23:59",
        ct_documento_estado_id : "-1",
        switch_mostrar : "1",
        grupoDocumentos: tipoComprobantesbyModulo,
        punto_venta: [],
        tercero: [],
        usuario: [],
        ciudad: []
    });


    // Handle input change
    
    const handleInputChange = (e) => {


        const { name, value, type, checked } = e.target;

        console.log("name:"  + name)
        console.log("value:" + value)
        console.log("type:" + type )
        console.log( "checked:" + checked )
        

        if(type == 'date' || type == 'text'|| type == 'radio'  || type == 'time' ){
            setFormData(prev => (
                {
                    ...prev , [name]:value
                }
            ))
        }
        return false;
       
        
    };

    // Handle form submission
    const handleSubmit = async (e) => {

        setCargando(1);
        e.preventDefault();
        // Simulate form submission (e.g., sending data to an API)
        console.log('Form Data Submitted:', formData);
        // Add your form submission logic here (e.g., API call)

        let data = await consultarDocumentos(formData);
  
        if(data){
            setCargando(null);
            console.log(data); 
            if(data != -1 ){

                $btnFiltrar.current.click();
                console.log(data);
                if(data.modo == 1 ){
                    ResultadoDatosfiltro(data.documentos)
                    ResultadoDatosfiltroItems(null)
                }else if(data.modo == 0) {
                    ResultadoDatosfiltro([])
                   ResultadoDatosfiltroItems(data.documentos)
                }

            }else{
                NotificationManager.error("Error de red")
            }
        }
        
        
    

    };



    return (
        <form  onChange={handleInputChange} className='container my-4'>
            <div className='d-flex justify-content-start'>
                <button ref={$btnFiltrar} className='btn btn-secondary' type='button' data-bs-toggle='collapse' data-bs-target='#collapseExample' aria-expanded='false' aria-controls='collapseExample'>
                    <i className='fa-solid fa-filter'></i> Filtrar Información
                </button>
            </div>

            <div className='collapse mt-3' id='collapseExample'>
                <div className='border border-translucent p-4 rounded card'>
                    <div className='row'>
                        <div className='col-12'>
                            <TipoDocumento tipoComprobantesbyModulo={tipoComprobantesbyModulo}  modulo={modulo}  formData={formData} setFormData={setFormData}  />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='dateDesde' className='form-label'>Desde</label>
                                    <input type='date' id='dateDesde' name={"desde"} className='form-control' defaultValue={formData.desde} />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='dateHasta' className='form-label'>Hasta</label>
                                    <input type='date' id='dateHasta' name={"hasta"} className='form-control'  defaultValue={formData.hasta}/>
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='hora1' className='form-label'>Hora inicio</label>
                                    <input type='time' id='hora1' name={"hora1"} className='form-control' defaultValue={formData.hora1} />
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <label htmlFor='hora2' className='form-label'>Hora Fin</label>
                                    <input type='time' id='hora2' name={"hora2"} className='form-control'  defaultValue={formData.hora2}/>
                                </div>


                            

                                <div className='col-md-12 mb-3'>
                                   <EstadoPagoDocumento formData={formData}/>
                                </div>
        
                                <div className='col-md-12 mb-3'>
                                   <SwitchProductos formData={formData}/>
                                </div>

                                {formData.switch_mostrar == 0 && <div className='col-md-12 mb-3'>
                                    {FiltrosProductos.map((filtro , idx) => (
                                        <div key={idx} className='form-group mb-3'>
                                            <Select filtro={filtro} setFormData={setFormData}  formData={formData}  />
                                        </div>
                                    ))}
                                </div>}

                            </div>
                        </div>
                        <div className='col-md-6'>
                            {filtros.map((filtro , idx) => (
                                <div key={idx} className='form-group mb-3'>
                                    <Select filtro={filtro} setFormData={setFormData}  formData={formData}  />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button disabled={cargando} onClick={handleSubmit} style={{maxWidth:300}} className='btn btn-lg btn-primary'>  {!cargando && <span>
                                Filtrar
                            </span>}

                            {cargando && <span>
                                Espere...
                            </span>}</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ListarTipos;