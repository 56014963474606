import React from 'react';
import FormTercero from './FormTercero';
import BuscarTercero from "./BuscarTercero";
import TerceroView from "./TerceroView";

import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";

import { useState } from 'react';
import { useEffect } from 'react';


import { useStateValue } from '../../../context/StateProvider';

import { DocumentL  } from "../../../components/Document/DocumentCore";

import {enviarPeticion} from "../../../Core/ActionDatabase";

import StandarModal from '../PartesDocument/StandarModal';

import InfoTerceroDocumentos from "./PartesItems/SubPartesItems/InfoTerceroDocumentos.jsx";
import ConfirmarCambioPrecio from './ConfirmarCambioPrecio.jsx';

let  Tercero_receroShow521 = null;

const Tercero = (tab) =>  {

    const [ {documento}  , dispatch ] = useContextDocumentValue();


    const [  showcuentaTerceroPendiente , SetshowcuentaTerceroPendiente] = useState();
    
    const [ TotalFavor , setTotalfavor ] = useState();

    const [ infosaldofavor  , setInfosaldofavor ] = useState([]);

    const [showformfast,  setShowformfast ] = useState(null);

    const [ {user} ] = useStateValue();

    const LogicaDocumento  =  new DocumentL(documento , null  , { dispatch, actionTypeDocument } , user  );


    /* Esta variable sera usada para indicarle a nuestro formulario si es nuevo o si es para editar **/
    const [accionForm , setAccionForm] = useState(null);


    /*** Este modal pregunta si quiere activar la lista de precio */
    const [showModal, setShowModal] = useState(false);
    
    useEffect( () => {

        setShowformfast(false);

    } , [tab] ) 

    const extraerTercero = (tercero) => {
 
        Tercero_receroShow521 = tercero;
      
        consultarCuentasPendientes(tercero);
        if(Tercero_receroShow521.ct_lista_precio_id > 0 && user.USER.type_user_id == 1 ){
            setShowModal(true);   
        }else{
            asignarCliente(null)
        }
        
    }


    const respuesta =  (Respuestaresultado) => {
        

        setShowModal(false);  
        asignarCliente(Respuestaresultado)
    }

    const asignarCliente = (Respuestaresultado) => {
        /***
         *  1=> Activar porcentaje
         *  2=> Activar precio  
         */
        let SWITCH_LISTA_PRECIO = 1;
        let copiaDC = documento;
        copiaDC.TERCERO  = Tercero_receroShow521;
        try {
            if(   user.CONFIGURACIONES_TABLE.switch_modo_precio &&  user.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){
                
                SWITCH_LISTA_PRECIO = 2;
            }
        } catch (error) {
            console.log(error);
        }

        LogicaDocumento.actualizar_lista_precio(Tercero_receroShow521.ct_lista_precio_id , SWITCH_LISTA_PRECIO ,  'agregarTercero' , Tercero_receroShow521 , Respuestaresultado);
    }

    const consultarCuentasPendientes = async (tercero) => {

        let respuesta =  await enviarPeticion( "ventas/consultar_cuentas_pendientes"  , null , {
            body : {
                tercero_id : tercero.id, 
                ct_master_id : documento.TIPO_DOCUMENTO.ct_master_id,
                ct_operacione_id:documento.TIPO_DOCUMENTO.ct_master_id,       
            },
        } );

       if( respuesta.respuesta && respuesta.respuesta == 1 ){ 
            
            if( respuesta.data.infoCuentasxPagarVenta   &&  respuesta.data.infoCuentasxPagarVenta.length ) {

                // Método 2: Usando la función reduce
                let sumaTotal2 = respuesta.data.infoCuentasxPagarVenta .reduce((acumulador, objeto) => acumulador + parseInt(objeto.total)  , 0);
             
                setTotalfavor(sumaTotal2)

                setInfosaldofavor( respuesta.data.infoCuentasxPagarVenta );

                SetshowcuentaTerceroPendiente(true);

            } 
       }
    }


    const eliminarTercero = () => {
    
        LogicaDocumento.actualizar_lista_precio(0 , null , 'quitarCliente');


    }



    


    const editarDatos = () => {
        setAccionForm(2);
        setShowformfast(true);
    }


    const handleSubmit = (tercero) => {
        
        extraerTercero(tercero);
        setShowformfast(false);
    }

    const handleSubmitMini = (tercero) => {
       
        extraerTercero(tercero);   
        setShowformfast(false);
    }

    

    const formFast = (data) => {
             
    }

    const ocultarForm = () => {
  
        setShowformfast(false);
    }

    const handleClickNuevo = () => {
        setShowformfast(!showformfast);
        setAccionForm(1);
    }

    /* <button onClick={mostrarFormulario} className='btn btn-primary btn-small' title="Registrar un nuevo Contacto"> <i class="fa-solid fa-user-plus"></i> </button>  */
    /*<StandarModal  titulo=" Nuevo Tercero" desactivar={mostrarFormulario}  mostrar={showFormulario} >  <FormTercero handleSubmitPadre={handleSubmit} terceroDefaul={ null } />  </StandarModal>  */
    
    return (
        <div className='' >   
            <div className='d-flex '>

                { ( true ) &&  
                    
                    <div style={{"position":" relative"}} >
                        
                        { !documento.TERCERO &&
                            <button   onClick={handleClickNuevo} className='btn btn-principal btn-small' title="Registrar un nuevo Contacto"  data-bs-placement="bottom" data-bs-toggle="tooltip" > 
                                {!showformfast && <i className="fa-solid fa-user-plus"></i> || <i className="fa-solid fa-xmark"></i>  } 
                            </button> 
                        }  

                        {
                            showformfast &&  <FormTercero terceroDefaul={documento.TERCERO} accionForm={accionForm}  handleSubmitPadre={handleSubmitMini} handleClickCerrar={ocultarForm}  /> 
                        }

                        
                    </div>
               }
              
                





                { (!documento ||   !documento.TERCERO) 
                    ? <BuscarTercero seleccionarTercero={extraerTercero}  ocultarform={ocultarForm} /> 
                    : <TerceroView tercero={documento.TERCERO}  eliminar={eliminarTercero} editar={editarDatos}  />
                }
            </div>
           


            {/***
            *  Modal 
            */}
            <StandarModal  headerShow={false} size="md"   title="Productos Compuestos"  desactivar={() => SetshowcuentaTerceroPendiente(!showcuentaTerceroPendiente)}  mostrar={showcuentaTerceroPendiente}  > 
                <InfoTerceroDocumentos TotalFavor={TotalFavor}   info_sado={infosaldofavor}  respuestaSi={ LogicaDocumento.AgregarSaldoAFavor }  cerrarModal={SetshowcuentaTerceroPendiente} /> 
            </StandarModal>

            
            <ConfirmarCambioPrecio show={showModal} respuesta={respuesta} />
        </div>
    );
}

export default Tercero;