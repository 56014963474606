import { LocalProvider , LocalContextReducer , initialContext, useContextLocalValue} from './Core/ContextTercero';
import TerceroPerfil2 from './TerceroPerfil2';
import { useParams } from 'react-router-dom';
import { __app } from '../../../utils/myApp';

const TerceroPerfil = ({id_directo , modalClose=false}) => {

    let id_2 = null;
    let  { id } = useParams();

    if(!id_directo){

        id_2 = __app.desenvolver(id);
    }else{
        id_2 = id_directo;
    }



    
    const initialState = {

        info  : {
            /*** Cada vez */
            actualizarPerfil       : __app.numeroFecha(),
            
            /*** Cada vez que realicen un pago a compras */
            actualizarTotalCompras : __app.numeroFecha(),
            
            /*** Cada vez que realicen un pago a Ventas */
            actualizarTotalVentas  : __app.numeroFecha(),

            /*** Actualizar cual quier pestaña del menu */
            menu : __app.numeroFecha(),
        }
    }
    return (
        <>
    
            <LocalProvider  initialState={initialState} reducer={LocalContextReducer}  >
                <TerceroPerfil2 id ={id_2} modalClose={modalClose}/>
            </LocalProvider>  
         
           
        </>
    );
};

export default TerceroPerfil;