import { enviarPeticion } from "../../../../Core/ActionDatabase";
import { getNit } from "../../../../Core/fetchLocalStoreData";
import { __app } from "../../../../utils/myApp";

export const HttpGetEmpresa = async(nit= null , reload = false ) => {
    
    
    if(nit === null){
        nit =  getNit();
    }
    const respuesta = await enviarPeticion("users/get_empresa",nit , {} , false);
   
    if(respuesta.status) {
        if(respuesta.data){

            localStorage.setItem('__Ah#d7aWF___', JSON.stringify(respuesta.data));

        }else{

            __app.alerta("Error de interno de servidor" , "warning" )
        }

    }else{
        __app.alerta("Error de Red" , "error" )
        
    }

    return respuesta;
}