import { ExtraerTablabyIds } from "../../../../Core/Dixie";
import { fetchUSer } from "../../../../Core/fetchLocalStoreData";
import { generarDocumentoFantasma } from "../../../Document/controlador";
import { DocumentL } from "../../../Document/DocumentCore";



export class Parqueadero{

    constructor() {
        
        
        this.USER = fetchUSer();
        
     

        this.PLACA = 'ABC123';


        /*
        Este Codigo es el servicio del parqueadero que por defecto ira incluido en todas las facturas de tipo parqueadero
        */
        this.CODIGO_SERVICIO_PARQUADERO = "1";


        this.VEHICULO = {
        
        };


        this.DOCUMENTO = {

        };
    }


    async renderizarPlaca (placa){
        
        this.log('Iniciando Proceso de renderizacion para la placa : ' +  placa )
        this.PLACA = placa; 


        //Preguntar si Existe
        let resultado = await  this.validarExistenciaPlaca();

        if(resultado){

            return 1;
            
        }else{

            return 0;
        }


    }


    async validarExistenciaPlaca  () {
        this.log('Validando su existencia' )
        var   data = await  ExtraerTablabyIds("vehiculos" , [ this.PLACA ]  , 'placa' );
        this.log('La siguiente linea es el resultado de la consulta' )
        this.log(data)

        if(data.length > 0 ){
            this.log('La placa fue encontrada exitosamente' )
            return true;
        }else{
            this.log('La placa no fue  encontrada ' )
            return false;
        }
    }


    async crearParqueaderoDirecto() {
        this.log('Iniciando Proceso de creaccion de parqueadero Directo.' )
        
        this.DOCUMENTO  =   generarDocumentoFantasma('dasda');
       
        let item  = await this.extraerItemParqueadero();
        this.insertarItemParqueadero(item);
    }


    insertarItemParqueadero(item){
        
        
        const datos = 
        {   
            item: item, 
            options: { 
                cantidad: 1,  
                PRECIO : 0
            } 
        } ;

        
        this.LogicaDocumento  =  new DocumentL(this.DOCUMENTO , null ,null , this.USER );
        
        console.log(datos);

        this.LogicaDocumento.despachador('insertarItem', datos);

        console.log(this.LogicaDocumento)
    }

    async extraerItemParqueadero(){
        this.log('extraerItemParqueadero' )
        var   data = await  ExtraerTablabyIds("productos" , [ this.CODIGO_SERVICIO_PARQUADERO]  , 'id' );
        this.log('El item encontrado es el siguiente' )
        if(data.length > 0 ){
            return data[0];
        }else{
            this.log('No tenemos producto relacionado con parqueadero. ' )
            return null;
        }
    }

    log (data){

        console.log(data);
    }
}