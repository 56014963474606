import LayautDefault from "./components/Layaut/LayautDefault";


/**import SimpleBar from 'simplebar-react'; */
/**import 'simplebar/dist/simplebar.min.css'; */



const  App  = () =>   {

   


    return (  
        <>  
      
           <LayautDefault />
           
        </>
    );
}

export default App;