
import { Numpositivo } from '../Core/MatematicasGlobales';
import { NotificationManager} from 'react-notifications';

export  const  __app = {
    

    alerta(data , estilo = "info"){
 
        if(estilo === "info"){
            NotificationManager.info(data);
        }

        if(estilo === "error"){
            NotificationManager.error(data);
        }

        if(estilo === "success"){
            NotificationManager.success(data);
        }

        if(estilo === "warning"){
            NotificationManager.warning(data);
        }

        
    },

    envolver(data){
        return data;
 
    },

    desenvolver(data){
        return data;
    
    },

    Sjhs(){
        return '454asd452';
    },

    redondearAlMultiploDe100(numero , DECIMAL  ) {

        if ( DECIMAL == 1){

            let num2 =  Number.parseFloat(numero).toFixed(3);
            return parseFloat(num2);
        }

       // return Math.round(numero / 100) * 100;
        

       if  (numero > 3000){
            return Math.round(numero / 100) * 100;
       }
         // Redondear hacia arriba
         let numeroRedondeado = Math.round(numero);

         // Ajustar al múltiplo de 10 más cercano
         if (numeroRedondeado % 10 !== 0) {
             numeroRedondeado = Math.round(numeroRedondeado / 10) * 10;
         }
 
         return numeroRedondeado;
    },


    redondearAlMultiploDe100FINAL_SUBTOTAL_3(numero) {

        if( numero > 1000 ){
            return Math.round(numero / 100) * 100;
        }

        return numero;
         
         
     },

    
    clonarObjeto :  (data) => {
        if(data) return  JSON.parse( JSON.stringify(data) );

        return {};
        
    },



    TipoMovil : function(){

        if (window.innerWidth <= 768) {
            // El ancho de la ventana es menor o igual a 768 píxeles, lo que podría indicar un dispositivo móvil.
            return true;
        }
       
        return null;
      
    },




    
    formato_decimal : function ( { total , decimal = 0 , color = false, neutro =  '' , icono = true , numerosPositivos = false  } ) {
 
        let  numero = 0;
  
        total =  Number.parseFloat(total).toFixed(3);
        
    

        if( color === true ){

            numero = this.formato_color_numero(total , neutro , icono , numerosPositivos );
        }else{
            
            numero = this.formato_decimal_2({ total,  decimal }); 

        }
        
        return  numero
       
    },


    formato_decimal_2: function ({ total, decimal = 1 }) {
        // Asegurarse de que el total es un número
        let numero = parseFloat(total);
    
        if (isNaN(numero)) {
            return "0";
        }
    
        // Redondear el número a dos decimales
        let numeroRedondeado = numero.toFixed(2);
    
        // Separar la parte entera y la parte decimal
        let [parteEntera, parteDecimal] = numeroRedondeado.split('.');
    
        // Formatear la parte entera con comas
        let parteEnteraFormateada = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
        // Devolver el número formateado con o sin decimales
        if (decimal == 1) {
            return `${parteEnteraFormateada}.${parteDecimal}`;
        } else {
            return parteEnteraFormateada;
        }
    },

    formato_color_numero : function( valor, neutro = '', iconoView = true ,  numerosPositivos = false , master_id = null   ){
       

        let numero1 = valor;
        let xxx = numerosPositivos ? Numpositivo(valor) :  valor ;
        let numeroformato = this.formato_decimal_2({ total: xxx});
        
    
        if (neutro === 'neutro' || master_id === 0) {
            
            return '<span class="text-dark p7-moneda"> $ ' + numeroformato  + ' </span>';
        }
    
    
    
        if (numero1 < 0) {

            numero1 = numero1 * -1;
            let  clase = 'text-warning';
            let  icono = iconoView ? '<i class="fa p7-fa-money fa-minus" aria-hidden="true"></i>':'';
            return '<span class="' + clase + ' p7-moneda"> ' + icono + ' $ ' + numeroformato + ' </span>';

        } else {
            let  clase = 'text-success';
            let  icono = iconoView ? '<i class="fa p7-fa-money fa-plus" aria-hidden="true"></i>':'';
            if (numero1 == 0) {
                clase = 'text-dark';
                icono = '';
            }
            return '<span class="' + clase + ' p7-moneda"> ' + icono + ' $ ' + numeroformato  + ' </span>';
        }
    },

    CARGANDO : function (cargando = 1){
        return false;
        const $cargando = document.getElementById('__CARGANDO');
 
        if( cargando ){
            $cargando.style.display = "block";
        }else{       
            $cargando.style.display = "none";
        }
    },

    getFecha : function (modo = 'fechaCompleta' ) {

        
        let xDate = new Date();
        
        let dia =   
        xDate.getFullYear() 
        + "-" + (xDate.getMonth()+1).toString(10).padStart(2,'0') 
        + "-" + xDate.getDate().toString(10).padStart(2,'0');
        
        
        let hora =  
        xDate.getHours().toString(10).padStart(2,'0')  
        + ":" + xDate.getMinutes().toString(10).padStart(2,'0') 
        + ":" +xDate.getSeconds().toString(10).padStart(2,'0') ;


        let fechaCompleta = dia + " " + hora;
        
        if ( modo === 'fechaCompleta' ) return fechaCompleta;
        if ( modo === 'dia' ) return dia;
        if ( modo === 'hora' ) return hora;
        
    },


    /** De un numero saca una fecha */
    numeroFecha : function (str) {
        if(!str) {
            str =  __app.getFecha();
        }


        //return str.replace(/[^a-zA-Z0-9 ]/g, '--') Esta es para conservar espacios
        return Number(str.replace(/[^a-zA-Z0-9]/g, ''))

    },


    

    formatoFechaGTM5 : function($fecha){

        if(!$fecha) return false;

        let objeto = $fecha.split(" ");

        let hora = objeto[1];
        let fecha = objeto[0];
        return fecha+'T'+hora+'-0500';
    },



    /** Convierte los valores espeficifos de una cadena y los concatena en un texto
     *  @params  
     *      obj  --> Objeto
     *      clave --> Clave a Concatenar
     *  @returns  Texto combiano
     */
    objetoATexto(obj , clave){

        let data = '';
        for(let ob of  obj ){
            if( ob[clave]){
                data += ob[clave] + ","
            }
        }
        data = data.substring(0, data.length - 1);
        return data;
    },
    

    TextoMinimo : function (texto , limite = 10){

        if(texto && texto.length > limite){
            return  texto.slice(0  , limite) + "...";
        }
        return texto;
       

    },

    moduloEnConstrucccion : function(){

        alert("⚠️ Este módulo está en construcción. Estamos trabajando para mejorarlo y pronto estará disponible, Si tienes preguntas o necesitas ayuda mientras tanto, por favor contacta a nuestro equipo de soporte.")
    }
    
}




export const __fecha = {

    converirMesaLetra($mes){

        switch ($mes) {
            case '01':
                $mes = "Enero";
            break;
            case '02':
                $mes = "Febrero";
            break;
            case '03':
                $mes = "Marzo";
            break;
            case '04':
                $mes = "Abril";
            break;
            case '05':
                $mes = "Mayo";
            break;
            case '06':
                $mes = "Junio";
            break;
            case '07':
                $mes = "Julio";
            break;
            case '08':
                $mes = "Agosto";
            break;
            case '09':
                $mes = "Septiembre";
            break;
            case '10':
                $mes = "Octubre";
            break;
            case '11':
                $mes = "Noviembre";
            break;
            case '12':
                $mes = "Diciembre";
            break;
        }
    
        return $mes;
    },
    
    
    
    
    converirMesAbreviado($mes){
    
        switch ($mes) {
            case '01':
                $mes = "Ene";
            break;
            case '02':
                $mes = "Feb";
            break;
            case '03':
                $mes = "Mar";
            break;
            case '04':
                $mes = "Abr";
            break;
            case '05':
                $mes = "May";
            break;
            case '06':
                $mes = "Jun";
            break;
            case '07':
                $mes = "Jul";
            break;
            case '08':
                $mes = "Ago";
            break;
            case '09':
                $mes = "Sep";
            break;
            case '10':
                $mes = "Oct";
            break;
            case '11':
                $mes = "Nov";
            break;
            case '12':
                $mes = "Dic";
            break;
        }
    
        return $mes;
    },
    
    
    formatoFecha({$fecha , $icon = 1  , $times = 1 , $fecha_view= 1}){
     
        if (!$fecha) {
            return "--------";
        }
        if ($fecha == "0000-00-00 00:00:00") {
            return "--------";
        }
    
        
        var $aux_fecha = $fecha.split(" ");
        $fecha = $aux_fecha[0];
        var $horaCompleta  = ( !$aux_fecha[1] ) ? '' : $aux_fecha[1];
    
    
    
    
        /**************** FECHA *********/
        var $fecha_formato = "";
        if($fecha_view == 1 ){
            var $fecha_array =  $fecha.split("-");
            var $dia = $fecha_array[2];
            var $mes = $fecha_array[1];
            var $año = $fecha_array[0];
            var $mes_letra = this.converirMesAbreviado($mes);
            $fecha_formato =     $dia + "/"  + $mes_letra + "/" + $año;  
            if ($icon) {
                $fecha_formato = '📅' + $fecha_formato;
            }
    
        }
    
    
    
    
        /********************** HORA ******************/
        var  $hora_formato = '';
        if ( $horaCompleta) {
           
            var $hora_array = $horaCompleta.split(":");
            var $hora   = $hora_array[0];
            var $minuto = $hora_array[1];
            var $segundo = $hora_array[2];
    
            $hora_formato =     $hora + ":"  + $minuto;
            if ($icon == 1 ) {
                $hora_formato = ' 🕜' + $hora_formato;
            }
        }





    
        var $resultado = $fecha_formato;
    
        if ($times == 1 ) {

            $resultado =   $fecha_formato +  "  "  +  $hora_formato;
        }
        
        return $resultado;
    
    },

    obtenerHora :  function (fechaHora) {

        try{
             // Separa la cadena en fecha y hora usando el espacio como delimitador
            const partes = fechaHora.split(' ');
            
            // Retorna la parte de la hora
            return partes.length === 2 ? partes[1] : null;
        }catch{
            return '--:--'
        }
       
    }

}