import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';

const Table = ({PAGOS}) => {
    console.log(PAGOS)
    return (
        <table className="table table-sm border-top border-translucent fs-8 mb-0 table-bordered">
            <tr>
                <th className="text-center">#</th>
                <th className="text-center">Fecha</th>
                <th className="text-right">Responsable</th>
                <th className="text-right">Forma de Pago</th>
                <th className="text-right">Banco</th>
                <th className="text-right">Punto de Venta</th>
                <th className="text-right">Turno</th>
                <th className="text-center">Total</th>
     
            </tr>
        

            <tbody>
                {PAGOS.map((item, key) => (
                    <>
                    {
                        item.estado == 1 &&
                        <tr key={key}>
                            <td>{item.id}</td>
                            <td>{__fecha.formatoFecha({$fecha : item.CREADO , $icon : false })}</td>
                            <td>{item.nombre_user}</td>
                            <td>{item.forma_pagos_nombre}</td>
                            <td>{item.banco_nombre}</td>
                            <td>{item.estacione_nombre}</td>
                            <td>#{item.turno_id}</td>
                            <td> <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.total , color : true , icono : false , numerosPositivos : true  } )} }></span></td>
                    
                          
                        </tr>
                    }
                    </>
                ))}
            </tbody>

        </table>
    );
};

export default Table;