import React from 'react';
import Moment from 'react-moment'
import 'moment/locale/es'  // without this line it didn't work
import { __app } from '../../utils/myApp';
function PedidoIndividual({duc , cargarDocumento ,eliminarCuentaAbierta, user  , imprimirPreFactura}) {
    return (
     
            <div  className=" " >
                    
                <div className='card  me-3'>
                    <div className="p-2  ">
                       

                        <div className='d-flex justify-content-evenly'>

                            <h5 className=" ">{duc.FORM.mesanombre} </h5>

                            { duc.cuentAbiertaFirebaseId &&  <div><span className='badge bg-warning'>Cta#{duc.cuentAbiertaFirebaseId}</span></div>  || <span>Cta#{duc.id}</span>}    
                        </div>
                       
                        { duc.FORM.nombre_vendedor   && <span className='d-block' > 🤵🏻 Mesero : { duc.FORM.nombre_vendedor  }</span> }

                        <span className="card-text"> ⏱<Moment fromNow interval={3000}>{__app.formatoFechaGTM5(duc.FORM.HoraComanda)}</Moment> </span>

                        { (duc.TERCERO && duc.TERCERO.nombrecomercial) && 
                            <div>
                                <span>  Cliente :  {duc.TERCERO.nombrecomercial}</span><br/>
                            </div>
                        }   

                    </div>
                    <ul className="list-group list-group-flush p-3 h-50">
                    {
                        duc.PRODUCTOS &&

                        <div >
                            {duc.PRODUCTOS.map((pro,index) => 
                        
                              <>
                                  <span key={index} className=" me-1 badge bg-primary">{pro.nombre}</span>

                                    {
                                        pro.COMPUESTO && <div>
                                            {pro.COMPUESTO.map((pro,index) => 
                                        
                                               <span className=' me-1' > <span key={index} className="badge bg-primary">{pro.nombre}</span></span>
                                                                    
                                            )}
                                        </div>
                                        
                                    }
                              </>

                                
                                                    
                            )}
                        </div>
                    }
                    </ul>
                    <div className="card-body p-2">

                        

                        <a onClick={() => cargarDocumento(duc)} className="btn-group btn btn-principal btn-sm card-link"> <span className=' text-sucess'> 💵  $ {__app.formato_decimal({ total : duc.MATEMATICAS.SUBTOTAL_3})}</span> </a>

                       

                    
                        <div class="btn-group float-end">
                            <button onClick={() => imprimirPreFactura(duc , 0 )} type="button" class="btn btn-principal"> <i class="fa-solid fa-print"></i> </button>
                            <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                            <span class="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul class="dropdown-menu">
                            <li><button  onClick={() => imprimirPreFactura(duc , 5 )}  class="dropdown-item" href="#">Con 5% de Propina</button></li>
                            <li><button  onClick={() => imprimirPreFactura(duc , 10 )}  class="dropdown-item" href="#">Con 10% de Propina</button></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li>{(user.PERMISOS.verBotonFacturarDocumento == 1 || user.USER.type_user_id == 1) && <a onClick={() => eliminarCuentaAbierta(duc)} className="card-link link">Eliminar</a>}</li>
                            </ul>
                        </div>

                   
                    </div>
                </div>
            </div>
    
    );
}

export default PedidoIndividual;