import React from 'react';
import InputValidation from './PartesFormAdd/InputValidation';
import Inventariable from './PartesFormAdd/Inventariable';

import Categorias from './PartesFormAdd/Categorias';
import StarCheckbox from './PartesFormAdd/StarCheckbox';

import InputCodigo from './PartesFormAdd/InputCodigo'
import PrecioImpuestos from './PartesFormAdd/PrecioImpuestos';

import ListaPrecio from './PartesFormAdd/ListaPrecio';
import Proporciones from './PartesFormAdd/Proporciones';


const AddCampos = ({deleteCodigo , asignarMultiCodigo , formData , formDataValidations , manejarImpuestos , user , setFormData , btnbmitLoad}) => {
    return (
        
                
                    <div className="row ">
                        
                        <div className="col-sm">
                            
                            
                
                
                            <div className="form-group row mb-3">    
                                <InputCodigo deleteCodigo={deleteCodigo} AsignarCodigo={asignarMultiCodigo} label={"Código"} formDataValidations={formDataValidations} defaultValue={formData.id_2}  formData={formData}  btnbmitLoad={btnbmitLoad} type="text" id="id_2" className="form-control" nameForm="id_2" placeholder="Código Personalizado"  />     

                                

                                {console.log(formData)}
                            </div>
                


                            
                            <div className="form-group row mb-3">   

                            {
                                /* 
                                    <input value={formData.referencia} autoComplete='off' btnbmitLoad={btnbmitLoad} type="text" id="referencia" className="form-control" name="referencia" placeholder="Referencia del producto"  />                                  
                                */
                            }
                                <InputValidation formData={formData} label={"Referencia"}  formDataValidations={formDataValidations}   autoComplete='off' btnbmitLoad={btnbmitLoad} type="text" id="referencia" className="form-control" nameForm="referencia" placeholder="Referencia del producto"  />   
                            </div>

                    
                            <div className="form-group mb-3">
                                <InputValidation   formData={formData} label={"Nombre de Producto"}  formDataValidations={formDataValidations}    autoComplete='off' btnbmitLoad={btnbmitLoad} type="text" id="nombre" className="form-control" nameForm="nombre" placeholder="Nombre del producto"  />   
                            </div>

                        
                            
                            <div className="form-group mb-3">
                                <InputValidation  formData={formData}  label={"Costo"}  formDataValidations={formDataValidations}    autoComplete='off' btnbmitLoad={btnbmitLoad} type="decimal" id="COSTO" className="form-control" nameForm="COSTO" placeholder="Costo o precio de compra"  />   
                            </div>

                            
                            {/** Switch para alternar entre un campo basico y uno Complejo. */}
                            {manejarImpuestos == 1 &&    
                
                                <PrecioImpuestos user={user} formData={formData}  setFormData={setFormData}  btnbmitLoad={btnbmitLoad} formDataValidations={formDataValidations}  />  
                            ||
                                <div className="form-group mb-3">
                                    <InputValidation  formData={formData}  label={"Precio de Venta"}  formDataValidations={formDataValidations}   autoComplete='off' btnbmitLoad={btnbmitLoad} type="decimal" id="PRECIO_PRINCIPAL" className="form-control" nameForm="PRECIO_PRINCIPAL" placeholder="Precio sin impuestos"  />   
                                </div>       
                            }
                                

                

                            
                                
                        </div>
                            
                        <div className="col-sm">

                            
                        
                            <Categorias  formData={formData} btnbmitLoad={btnbmitLoad}   formDataValidations={formDataValidations}     />

                          
                            <Proporciones  formData={formData} btnbmitLoad={btnbmitLoad}   formDataValidations={formDataValidations}     />

                            <div className="form-group mb-3">
                                <InputValidation  formData={formData}  label={"Peso"}  formDataValidations={formDataValidations}    autoComplete='off' btnbmitLoad={btnbmitLoad} type="decimal" id="peso" className="form-control" nameForm="peso" placeholder="Cantidad por la que viene el producto"  />   
                            </div>

                            <Inventariable formData={formData} btnbmitLoad={btnbmitLoad}   formDataValidations={formDataValidations}     />

                            
                            
        
                            
                            <ListaPrecio user={user} formData={formData}  setFormData={setFormData} btnbmitLoad={btnbmitLoad} /> 
                            

                        


                            { (user.CONFIGURACIONES_TABLE.abarrotes && user.CONFIGURACIONES_TABLE.abarrotes == 1) &&   
                            <div className="form-group mb-3">
                                <InputValidation  formData={formData}  label={"Cantidad Default"}  formDataValidations={formDataValidations}    autoComplete='off' btnbmitLoad={btnbmitLoad} type="decimal" id="cantidad_default" className="form-control" nameForm="cantidad_default" placeholder="Si es una cantidad variable escriba 0"  />   
                            </div>}

                        
                            <StarCheckbox  formData={formData} />


                        
                        </div>
                    </div>
                



                   
    );
};

export default AddCampos;