import { off, set , getDatabase , ref} from 'firebase/database';
import {enviarPeticion} from '../../Core/ActionDatabase';
import { reloadDatabases , deleteIdsDixie } from '../../Core/Dixie';
import { __app, __fecha } from '../../utils/myApp'; 
import { NotificationManager} from 'react-notifications';

import {   getNit} from "../../Core/FireBase"

export const  inicial = {
    "id_2": "",
    "referencia": "",
    "nombre": "",
    "PRECIO_PRINCIPAL": "" ,
    "PRECIO_FINAL": "" ,
    "COSTO": "" ,
    "productogrupo_id": "",   
    "inventariable" : "",
    "cantidad_default" : 1,
    "favoritos" : 1,
    "proporcion" : "1",
    "peso" : "0",
    "IMPUESTOS_RAIZ" : [],
    "COMPOSICIONES" : [],
    "IMAGENES" : [],
    "INVENTARIO" : {
        VARIANTES : [
            
        ]
    },
    
}


export const  validaciones = {
    "id_2": {

        required : {
            mensaje : 'El Código del producto es Obligatorio.',
            valor  : true 
        },

        caracterMinimo : {
            mensaje : 'El valor minimo permito es.',
            valor  : 2 
        },

        unico : {
            mensaje : 'Ya existe un valor en la base de datos con este campo.',
            valor : true,  
        },

        maximoCaracteres : {
            mensaje : 'Superar el maximo de valor de Caracteres.',
            valor : 12,  
        },
    },


    "referencia": {


        caracterMinimo : {
            mensaje : 'El valor minimo permito es.',
            valor  : 1 
        },

        unico : {
            mensaje : 'Ya existe un valor en la base de datos con este campo.',
            valor : true,  
        },
    },

    

    
    "nombre": {

        required : {
            mensaje : 'El nombre del producto es Obligatorio.',
            valor  : true 
        },

        caracterMinimo : {
            mensaje : 'El valor minimo de caracteres  permito es.',
            valor  : 3 
        },

        unico : {
            mensaje : 'Ya existe un valor en la base de datos con este nombre.',
            valor : true,  
        },

        maximoCaracteres : {
            mensaje : 'Superar el maximo de valor de Caracteres.',
            valor : 12,  
        },
    },

    "PRECIO_PRINCIPAL": {

        required : {
            mensaje : 'El Precio del producto es Obligatorio.',
        },

        numerico : {
            mensaje : 'Solo se aceptan numeros.',    
        },


        numeroMinimo : {
            mensaje : 'No aceptamos un numero menor a  0',   
            valor : 0, 
        },
    },


    "COSTO": {

        required : {
            mensaje : 'El Costo del producto es Obligatorio.',
        },

        numerico : {
            mensaje : 'Solo se aceptan numeros.',    
        },


        numeroMinimo : {
            mensaje : 'No aceptamos un numero menor a  0',   
            valor : 0, 
        },
    },


    "productogrupo_id": {
        required : {
            mensaje : 'Seleccione una Categoría',
        },

        numeroMinimo : {
            mensaje : 'Seleccione una Categoría ',   
            valor : 1, 
        },

    },

    "inventariable": {
        required : {
            mensaje : 'Por favor indica si este producto es inventariable o no.',
        },

    },

}





/*
*  Esta Funcion solicita al servidor los ultimos cambios de los productos
*  @param Por ahora el I
*/
export const SincronizarNivelBajo = async() => {
    
    const lastKey = (localStorage.getItem("firebaseLastKeyProducto") ) ? localStorage.getItem("firebaseLastKeyProducto") :  undefined ; 
    
    let respuesta = null;
    
    if (lastKey != undefined){

        respuesta = await enviarPeticion( "productos/sincronizar_nivel_bajo"   , lastKey);
       
        if(respuesta.respuesta == 1){
           
            if(respuesta.data.lastKey){
                localStorage.setItem("firebaseLastKeyProducto" , respuesta.data.lastKey )
            }

            if(respuesta.data.productos.length > 0 ){
                reloadDatabases(respuesta.data.productos, 'productos');
                logdeProducotosSincronizados(respuesta.data.productos);
            }


            if(respuesta.data.idsDelete.length > 0 ){
                let  refIds = respuesta.data.idsDelete.map(registro => registro.ref_id);
                deleteIdsDixie(refIds , 'productos');

                let copy = respuesta.data.idsDelete.map( (data) => {

                    return {
                        ...data,
                        nombre : 'Producto Eliminado ',
                        id_2 : data.ref_id
                    }
                })

                console.log(copy);

                logdeProducotosSincronizados(copy);
            }


            return respuesta.data;
        }

    }else{
        
        NotificationManager.error("Le Recomendamos Sincronizar todos los productos.")
        return false;
    }  
}





const logdeProducotosSincronizados = (productos) => {

    productos.forEach((producto) => {
        producto.fechaSincronizacion = __app.getFecha() // Agregar la propiedad con la fecha actual
        producto.fechanumero = __app.numeroFecha(producto.fechaSincronizacion);
    })

    console.log(productos);
    reloadDatabases( productos , 'actualizaciones');
}

// Función para agregar la propiedad de "FECHA de sincronización"




export const SincronizarTodo = async() => {

    console.log("Sincronizado TODO... Ejecutado Esta Accion puede Ser peligrosa.")

    let respuesta = null;
    respuesta = await enviarPeticion( "productos/listar_productos");
   
    if(respuesta.respuesta == 1){

        console.log("a")
        if(respuesta.data.lastKey){
            localStorage.setItem("firebaseLastKeyProducto" , respuesta.data.lastKey )
        }

        
        if(respuesta.data.productos.length > 0 ){
            
            console.log("b")

            /** Convinar La lista de precio con los productos */
            let newProducto = respuesta.data.productos.map( (pro, index) => {
               

                if (Array.isArray(respuesta.data.listadeprecios)) {
                    pro = {...pro , LISTA_PRECIOS : respuesta.data.listadeprecios.filter( (lista) => lista.producto_id == pro.id  )}
                }
                
                if (Array.isArray(respuesta.data.listarImpuestos)) {
                    pro = {...pro , IMPUESTOS_RAIZ : respuesta.data.listarImpuestos.filter( (impuesto) => impuesto.ct_producto_id == pro.id  )}

                }
                return pro

            })

            console.log("c")

            return newProducto
        }else{
            console.log("no hay productos")
        }
    }
     
}






/**** Esta funcion convina la lista de precio con los impuestos para evitar doble iteraciones */
export const combinarProductos =  ({InfoListaPrecio, infoImpuestos}  , productos ) => {

    
    console.log("CArgando...")
    
    console.log("productos")

    let listaPreciosPorProducto = {};
    let impuestosPorProducto = {};

    // Crear asociaciones por producto_id
    InfoListaPrecio.data.listadeprecios.forEach(lista => {
        
        if (!listaPreciosPorProducto[lista.producto_id]) {
            
            listaPreciosPorProducto[lista.producto_id] = [];
        }

        listaPreciosPorProducto[lista.producto_id].push(lista);
    });

 
    infoImpuestos.data.listarImpuestos.forEach(impuesto => {
        
        if (!impuestosPorProducto[impuesto.ct_producto_id]) {
           
            impuestosPorProducto[impuesto.ct_producto_id] = [];
        
        }
        impuestosPorProducto[impuesto.ct_producto_id].push(impuesto);
    });

    
  

    // Mapear los productos con sus respectivas asociaciones
    let newProducto = productos.map(pro => {
        return {
            ...pro,
            LISTA_PRECIOS: listaPreciosPorProducto[pro.id] || [],
            IMPUESTOS_RAIZ: impuestosPorProducto[pro.id] || [],
        };
    });
    console.log(newProducto)
    return newProducto;
}








export const getTablaAuxProductos = async() => {

    let respuesta = null;
    respuesta = await enviarPeticion( "productos/get_tabla_aux_productos");
    if(respuesta.respuesta == 1){
        return respuesta.data;     
    } else{
        return [];
    }   
}

export const ExtraerComponentedeProductos = async () => {

    let infoImpuestos   = null;
    let InfoListaPrecio = null;

    infoImpuestos = await enviarPeticion( "productos/get_impuestos_all");
    
    if(infoImpuestos.respuesta == 1){
        
        InfoListaPrecio = await enviarPeticion( "productos/get_lista_precio_all");

        if(InfoListaPrecio.respuesta == 1){

            return {InfoListaPrecio , infoImpuestos }
        }
    } else{
        return [];
    } 
}

export  const extraerCantidadDeProductosBD = async () => {

    let  res = await enviarPeticion( "productos/contar_cantidad_productos");   
    
    if(res.respuesta == 1){ 
        console.log(res.data)
        return res.data.cantidad;
    }else{
        return 0;
    }
}

export  const cargarProductosByPagina = async (inicio, fin ) => {

    let  res = await enviarPeticion( "productos/cargar_productos_by_paginas" , inicio + "/" + fin  );   
    
    if(res.respuesta == 1){ 
        return res.data.productos;
    }else{
        return [];
    }
}

export const SumarImpuestosPrecio = ( producto) => {


    if(producto.IMPUESTOS_RAIZ && producto.IMPUESTOS_RAIZ.length > 0){

         // Suponiendo que tienes el objeto JSON en una variable llamada 'producto'
        let precioFinal = parseFloat(producto.PRECIO_PRINCIPAL);
        let porcentajeTotalImpuestos = 0;

        producto.IMPUESTOS_RAIZ.forEach(impuesto => {
            if( impuesto.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(impuesto.importe);
                porcentajeTotalImpuestos += porcentajeAumento;
            }
        
        });
        precioFinal += precioFinal * (porcentajeTotalImpuestos / 100);

        return  __app.redondearAlMultiploDe100(precioFinal);

    }
    return  __app.redondearAlMultiploDe100(producto.PRECIO_PRINCIPAL); 
}

export const guardarProductosServer  = async (producto ) => {
  
  




    const formData = new FormData();

    // Agregar todos los datos excepto las imágenes
    for (let key in producto) {
        if (key !== 'IMAGENES') {
            if (typeof producto[key] === 'object') {
            formData.append(key, JSON.stringify(producto[key]));
            } else {
            formData.append(key, producto[key]);
            }
        }
    }

    // Agregar las imágenes

    if( producto.IMAGENES &&  producto.IMAGENES.length > 0 ){
        producto.IMAGENES.forEach((image, index) => {
            formData.append(`image_${index}`, image.file);
        });
    }
    



    let responseHttp =  await enviarPeticion( "productos/guardar_producto", null , {
        body : formData,
    } );

    
    if(responseHttp ){
        let statushttp = 1;
        return { statushttp , responseHttp }
    }
}


export const setCantidadInventario  = async (data ) => {

 
    let responseHttp =  await enviarPeticion( "inventarios/guardar_cantidad_inventario", null  , {
        body : data,
        headers :{ 
            
        }
    } );

    if(responseHttp ){
        console.log(responseHttp)
        if(responseHttp.respuesta &&  responseHttp.respuesta == 1 ) {
            NotificationManager.success("Inventario Actualizado")
            return responseHttp;
        }else{
            NotificationManager.error("Error al  Actualizar inventario")
            return responseHttp;
        }
    }else{
        NotificationManager.error("Error al  Actualizar inventario")
        return responseHttp;
    }
}

    
export const extraerUltimoid = async (user) => {
    let requesthttp = await enviarPeticion("productos/ultimo_id_2");

    if(requesthttp.data && requesthttp.data) {
        return definirNumero(requesthttp, user); 
    }else{
       
        return definirNumero({ ... requesthttp , ...{data : {id:1}  }  } , user);
    }   
}

export const definirNumero = (requesthttp , user) => {

    if( user.CONFIGURACIONES_TABLE.numero_de_digitos_productos   ){      
        let cifrasPermitidas =  user.CONFIGURACIONES_TABLE.numero_de_digitos_productos.length; 
        let miNumero = requesthttp.data.id; 

        if( parseInt(miNumero) > 0  ){

        }else{
            miNumero = 1;
        }
     
        let resultado =  miNumero.toString().padStart(cifrasPermitidas, '0');  
        return resultado;
    }else{
        
        return -1;
    }  

}
export const combinarCamposListaPrecio = (formData , user) => {


    // Si esta Activa para manejar Lista de precio y la lista tiene datos
    if (
        
        user.CONFIGURACIONES_TABLE.listadeprecio && 
        user.CONFIGURACIONES_TABLE.listadeprecio == 1 
        
        && user.DATABASE.ct_lista_precios && 
        user.DATABASE.ct_lista_precios.length > 0)
    {

        console.log("aaaAAAA")
        const datosTransformados = {

            "LISTA_PRECIOS": user.DATABASE.ct_lista_precios.map(item => { 
                

                /** En caso se una edicion verificamos el precio de la lista de precios. Si no cargaremos el que esta por defecto */
                let infoPrecio = null 
                if(formData.LISTA_PRECIOS){

                    infoPrecio = formData.LISTA_PRECIOS.find( data => (data.ct_lista_precio_id == item.id ));
                }
                
             

                let valor =  (infoPrecio) ?  infoPrecio.precio     :  item.valor;

                let data =     {
                    id: item.id,
                    nombre: item.nombre,
                    precio: valor
                }
                
                return data;
            })
        };
        
        console.log(datosTransformados)

       return {
            ...formData,
            ...datosTransformados
        }

        
    }

    return formData

 
}
   


export const  insertarCambioFirebase = (data) => {
    console.log(data);
    const db = getDatabase();
    const listener = ref(db, 'cambiosdatabase/'+getNit()+'/productos/' ) 
    set(listener  , data  ) .then(() => {
        console.log("Data savedProductos!"); 
        NotificationManager.success('Producto Sincronizado');
    })
    .catch((error) => {
        // The write failed...
    });
}


export const  cargarUltimaKey = async () => {
    let responseHttp =  await enviarPeticion( "productos/extraer_ultima_key" );
    if(responseHttp ){  
        if(responseHttp.respuesta == 1){
            if(responseHttp.data.lastKey){
                localStorage.setItem("firebaseLastKeyProducto" , responseHttp.data.lastKey );
                NotificationManager.success('Key de Sincronizacion consultada con Exito.');
            }else{

                NotificationManager.error('No hay un key Disponible');
            }
        }else{
            NotificationManager.error('El servidor no responde');
        }    
    }else{
        NotificationManager.error('No hay RED');
    }
}


export const cargarInventariobyProductoid  = async ( id ) => {

 
    let responseHttp =  await enviarPeticion( "inventarios/cargar_inventario_by_id", id  );

    if(responseHttp ){
      
        if(responseHttp.respuesta &&  responseHttp.respuesta == 1 ) {
          
            return responseHttp.data;
        }
    }else{
        NotificationManager.error("Sin Red")
    }
    return 'error';
}


export const cargarInventario  = async ( param ) => {

 
    let responseHttp =  await enviarPeticion( "inventarios/cargar_stock_inventario_todos", param  );

    if(responseHttp ){
      
        if(responseHttp.respuesta &&  responseHttp.respuesta == 1 ) {
          
            return responseHttp.data;
        }else{
            NotificationManager.error("Sin Red")
        }
    }else{
        NotificationManager.error("Sin Red")
    }
    return [];
}

export const consultarAgotados =  async (estacion_id) => {

    let responseHttp =  await enviarPeticion( "inventarios/consultar_agotados", estacion_id  + "/"  + 1  );

    if(responseHttp ){
      
        if(responseHttp.respuesta &&  responseHttp.respuesta == 1 ) {
          
            return responseHttp.data;
        }else{
            NotificationManager.error("Sin Red")
        }
    }else{
        NotificationManager.error("Sin Red")
    }
    return [];
}

export const extraerCompuestos = (item) =>  {



    let formatoCompuesto = [];

    if(item.COMPUESTO && item.COMPUESTO.length > 0 ){

        item.COMPUESTO.map( (com , index ) => {

            let news = {
                producto_id : com.id,
                nombre : com.nombre,
                cantidad :com.cantidad_composicion,
                PRECIO_COMPUESTO : com.PRECIO_COMPUESTO,
            }

            formatoCompuesto = [...formatoCompuesto , news];

        } ) 
    }

    return formatoCompuesto;
}